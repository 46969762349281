@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  src: local('Montserrat'), url(./fonts/Montserrat/Montserrat-ExtraLight.ttf) format('opentype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: local('Montserrat'), url(./fonts/Montserrat/Montserrat-Light.ttf) format('opentype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: local('Montserrat'), url(./fonts/Montserrat/Montserrat-Medium.ttf) format('opentype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: local('Montserrat'), url(./fonts/Montserrat/Montserrat-SemiBold.ttf) format('opentype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: local('Montserrat'), url(./fonts/Montserrat/Montserrat-Bold.ttf) format('opentype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  src: local('Montserrat'), url(./fonts/Montserrat/Montserrat-ExtraBold.ttf) format('opentype');
}

@layer base {
  html {
    font-family: Montserrat, system-ui, sans-serif;
  }
}

$family-sans-serif: 'Montserrat';

button {
  font-family: 'Montserrat' !important;
}