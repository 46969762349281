.banners-slick {
  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .slick-slide > div {
    height: 100%;
    width: 100%;
  }
}

.giftShortList-slick {
  .slick-dots {
    bottom: -2rem;
    li {
      opacity: 0.5;
      width: 1rem;
      margin: 0px;
    }
    .slick-active {
      width: 1rem;
      opacity: 1;
    }
    .slick-active div {
      width: 1rem;
    }

    li:first-child > div {
      border-radius: 8px 0 0 8px;
    }

    li:last-child > div {
      border-radius: 0 8px 8px 0;
    }
  }

  .slick-track {
    display: flex !important;
    padding-bottom: 0.5rem;
  }

  .slick-slide {
    height: inherit !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .slick-slide > div {
    height: 100%;
    width: 100%;
  }
}

.custom-error-support-home-page {
  color: var(--blue-medium-electric-blue-0757-a-2, #0757a2);
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  padding-bottom: 20;
}

.custom-error-support-home-page-phone {
  color: var(--blue-medium-electric-blue-0757-a-2, #0757a2);
  text-align: center;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  text-decoration-line: underline;
}

.custom-error-div-home-page {
  padding: 40px 16px 0px;
}

.custom-error-home-page-content {
  color: var(--Red, #f5293a);
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.4;
  // padding: 0px 10px 0px;
}

.custom-home-page-button-submit {
  width: 100% !important;
  height: auto !important;
  margin-bottom: 16px !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  border-radius: 64px !important;
  box-shadow: none !important;
  background: var(--Primary, #005eb8);
}

.custom-home-page-button-return {
  width: 100% !important;
  height: auto !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  border-radius: 64px !important;
  box-shadow: none !important;
  border: 1px solid var(--Primary, #005eb8) !important;
  background-color: var(--White, #fff) !important;
  color: #005eb8 !important;
}

.custom-home-pc-logo {
  @media only screen and (max-height : 800px) {
    margin-top: 10px !important;
    gap: 1rem !important;
    padding-bottom: 0px !important;
  }
}
.custom-home-pc-notify {
  @media only screen and (max-height : 800px) {
    gap: 0rem !important;
  }
}
.custom-home-pc-content-font{
  @media only screen and (max-height : 800px) {
    font-size: 20px !important;
  }
}
.custom-home-pc-pr {
  @media only screen and (max-height : 800px) {
    width: 200px !important;
  }
}

//CUSTOM SELECT PROVINCE
.custom-select {
  position: relative;
  width: 400px;
  max-width: 100%;
  font-size: 1.15rem;
  color: #000;
  margin-top: 1.5rem;
}

.select-dropdown-province {
  position: absolute;
  list-style: none;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  padding: 10px 0px;
  margin-top: 10px;
  max-height: calc(100vh - 420px);
  overflow-y: auto;
}

.select-dropdown-province li {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  font-size: 24px;
  line-height: 1.4;
}

.custom-info-text {
  color: var(--Black, #060606);
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.selected-province {
  background-color: #005eb8;
  color: #fff;
  border: 1px solid #005eb8;
  border-radius: 8px;
}
.selected-province > .icon {
  display: inline-block;
}

.icon {
  display: none;
}