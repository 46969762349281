:root {
  --primary: #005eb8;
  --white: #fff;
  --grayWhite: #e8e8e8;
  --black: #060606;
}

.PhysicalOTPInput__input {
  padding: 8px;
  box-shadow: 0px 4px 13px 0px rgba(182, 182, 182, 0.2);
  border-radius: 8px;
  margin: 8px;
  width: 69px !important;
  height: 69px;
  line-height: 1.4;
  font-size: 24px;
  font-weight: 700;
  font-family: 'Montserrat';
  background-color: var(--grayWhite);
  color: var(--primary);

  @media only screen and (max-width: 320px) {
    width: 60px !important;
    height: 60px;
  }
}
.PhysicalOTPInput__input:focus {
  outline: none !important;
  border: 1px solid var(--primary);
  background-color: var(--white);
  color: var(--black);
}
.PhysicalOTPInput__input:disabled {
  opacity: 0.5;
}
.PhysicalOTPInput__input__value {
  background-color: #ffffff;
}

.customNameField {
  input {
    text-transform: capitalize;
  }
}
